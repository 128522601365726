<template>
  <section class="listing">
    <div class="listing__action-bar">
      <q-btn
        dense
        flat
        text-color="secondary"
        label="Back"
        @click="navigateBack"
        icon="sym_r_arrow_left_alt"
      />
    </div>

    <div v-if="vehicleLoaded && !loading" class="listing__content">
      <div class="listing__main-content q-gutter-y-lg q-mb-xl">
        <q-card
          flat
          :square="windowWidth <= 1025"
          class="bg-background q-mb-lg"
        >
          <ImageCarousel
            :images="vehicle.images"
            :ratio="windowWidth < 768 ? 4 / 3 : 16 / 9"
            chevron-size="40px"
            class="--shadow__bottom"
          />
        </q-card>

        <q-card
          v-if="windowWidth <= 1025"
          square
          class="bg-background-light q-mt-none"
        >
          <q-card-section v-if="vehicle.tags.length > 0" class="q-pb-none">
            <div class="flex q-gutter-x-sm">
              <q-badge
                v-for="(tagData, i) in vehicle.tags"
                :key="i + '_tagDataBadge'"
                :label="tagData.tag"
                :color="
                  {
                    'Price Drop': 'positive',
                    'New Listing': 'blue',
                    'Low Mileage': 'positive-dark',
                    'High Mileage': 'negative-dark'
                  }[tagData.tag]
                "
              />
            </div>
          </q-card-section>

          <q-card-section class="q-pb-sm">
            <h4 v-html="vehicle.title" class="--no-style q-mb-none" />
            <p class="q-ma-none text-text-light">{{ vehicle.trim }}</p>
          </q-card-section>

          <q-card-section class="q-py-sm">
            <div class="row no-wrap items-end justify-between">
              <div class="row no-wrap items-center">
                <h3 class="text-primary q-mb-none" style="font-weight: 800">
                  {{ vehicle.price_formatted }}
                </h3>
                <div
                  v-if="
                    vehicle.tags.filter(tag => tag.tag === 'Price Drop')
                      .length > 0
                  "
                  class="column items-center justify-center text-positive q-ml-sm"
                >
                  <q-icon
                    name="sym_r_trending_down"
                    style="font-size: 21px;line-height: 21px"
                  />
                  <span
                    class="leading-none text-weight-bold --text-sm"
                    style="margin-top: -6px"
                    >${{
                      formatNumber(
                        vehicle.tags.filter(tag => tag.tag === "Price Drop")[0]
                          .metadata.amount_change,
                        true
                      )
                    }}</span
                  >
                </div>
              </div>
              <div class="column items-end">
                <h6 class="--no-style q-mb-none row no-wrap items-center">
                  <q-icon name="sym_r_speed" class="q-mr-xs" size="22px" />
                  <span
                    v-html="vehicle.mileage_formatted + ' miles'"
                    class="ellipsis"
                  />
                  <!--<q-icon v-if="vehicle.tags.filter(tag => tag.tag === 'Low Mileage').length > 0" name="sym_r_keyboard_double_arrow_down" size="27px" color="positive-dark" style="margin: -2px -5px 0 2px"/>
                  <q-icon v-if="vehicle.tags.filter(tag => tag.tag === 'High Mileage').length > 0" name="sym_r_keyboard_double_arrow_up" size="27px" color="negative-dark" style="margin: 0 -5px 0 2px"/>-->
                </h6>
                <p
                  class="row no-wrap items-center q-mb-none --text-sm text-text-light"
                  style="white-space: nowrap; margin-top: 2px"
                >
                  Est. ${{ vehicle.estimated_monthly_payment }}/mo
                  <q-icon class="q-ml-xs" name="sym_r_info" size="12px">
                    <q-tooltip
                      anchor="bottom right"
                      self="top right"
                      :offset="[5, 5]"
                      max-width="300px"
                    >
                      Monthly payment estimate of ${{
                        vehicle.estimated_monthly_payment
                      }}
                      is based on a 72 month loan at 5.2% APR with a 20% down
                      payment.
                    </q-tooltip>
                  </q-icon>
                </p>
              </div>
            </div>
          </q-card-section>

          <q-separator inset />

          <q-card-section class="q-py-sm">
            <div
              class="row no-wrap items-center justify-between --text-sm text-text-light"
            >
              <p
                class="row items-center no-wrap --no-style q-mb-none"
                style="font-weight: 500"
              >
                <q-icon name="sym_r_place" class="q-mr-xs" size="15px" />
                <span
                  v-html="vehicle.dealer.city + ', ' + vehicle.dealer.state"
                  class="ellipsis"
                />
              </p>
              <p
                v-if="'distance' in vehicle"
                class="truncate"
                style="margin-top: -2px"
              >
                {{ vehicle.distance + " miles away" }}
              </p>
            </div>
          </q-card-section>
        </q-card>

        <q-card :square="windowWidth <= 1025" class="listing__content-card">
          <q-card-section class="listing__content-card__header">
            <h5>
              Vehicle Details
            </h5>
          </q-card-section>
          <q-card-section class="q-pt-sm">
            <q-list class="row">
              <q-item
                v-for="detail in vehicleDetailsListData"
                :key="detail.label + '_vehicleDetailItem'"
                class="listing__vehicle-details-list-item q-px-none col-12 col-md-6"
              >
                <div
                  class="listing__vehicle-details-list-item-content ellipsis"
                >
                  <small
                    v-html="detail.label"
                    class="text-text-light text-uppercase q-mr-md q-mb-none text-weight-bold"
                    style="width: 100px"
                  />
                  <p
                    v-html="detail.value"
                    class="--no-style q-mb-none ellipsis"
                  ></p>
                </div>
              </q-item>
            </q-list>
          </q-card-section>
        </q-card>

        <q-card
          :square="windowWidth <= 1025"
          v-if="vehicle.features.length > 0"
          class="listing__content-card"
        >
          <q-card-section class="listing__content-card__header">
            <h5>
              Vehicle Features
            </h5>
          </q-card-section>
          <q-card-section
            v-if="vehicle.features.length > 0"
            class="row justify-between items-start"
          >
            <q-item
              v-for="(feature, i) in vehicle.features"
              :key="i + '_vehicleFeatureItem'"
              class="col-12 col-md-6 q-pl-none"
              dense
            >
              <div class="row no-wrap items-center">
                <q-icon
                  name="sym_r_fiber_manual_record"
                  class="q-mr-sm"
                  style="font-size: 8px"
                />
                <p v-html="feature" class="q-mb-none"></p>
              </div>
            </q-item>
          </q-card-section>
        </q-card>

        <q-card :square="windowWidth <= 1025" class="listing__content-card">
          <q-card-section class="listing__content-card__header">
            <h5>
              Dealer Information
            </h5>
          </q-card-section>
          <q-card-section class="row q-col-gutter-y-md">
            <div class="col-12 col-md-6">
              <p class="q-ma-none text-weight-bold">
                {{ vehicle.dealer.name }}
              </p>
              <p class="q-ma-none text-text-light --text-sm">
                {{ vehicle.dealer.address }}
              </p>
              <p class="q-ma-none text-text-light --text-sm">
                {{ vehicle.dealer.city }}, {{ vehicle.dealer.state }}
              </p>
            </div>
          </q-card-section>
          <q-card-section class="q-pt-none">
            <iframe
              width="100%"
              height="366"
              style="border:0; filter: invert(90%) hue-rotate(180deg)"
              loading="lazy"
              allowfullscreen
              :src="
                'https://www.google.com/maps/embed/v1/place?key=AIzaSyBchU_pFfwvEIGYVQivG_BXO3EsiX0Bnw0&zoom=10&q=' +
                  encodeURI(vehicle.dealer.name) +
                  ', ' +
                  encodeURI(vehicle.dealer.city) +
                  ', ' +
                  encodeURI(vehicle.dealer.state)
              "
            >
            </iframe>
          </q-card-section>
          <q-card-section v-if="vehicle.description.length > 0">
            <p class="text-weight-bold --text-sm q-mb-xs">Dealer's Notes</p>
            <p class="--text-sm text-text-light" v-html="vehicle.description" />
          </q-card-section>
        </q-card>

        <q-card-section
          v-if="windowWidth <= 1025"
          class="bg-background q-py-lg --shadow__top"
          style="position: fixed; bottom: 0; left: 0; right: 0; z-index: 3;"
        >
          <q-btn
            type="submit"
            color="secondary"
            label="Check Availability"
            class="full-width"
            @click="promptLeadFormDialog"
          />
        </q-card-section>
      </div>
      <div v-if="windowWidth > 1025" class="listing__side-content">
        <q-card class="bg-background-light q-mt-none">
          <q-card-section v-if="vehicle.tags.length > 0" class="q-pb-none">
            <div class="flex q-gutter-x-sm">
              <q-badge
                v-for="(tagData, i) in vehicle.tags"
                :key="i + '_tagDataBadge'"
                :label="tagData.tag"
                :color="
                  {
                    'Price Drop': 'positive',
                    'New Listing': 'blue',
                    'Low Mileage': 'positive-dark',
                    'High Mileage': 'negative-dark'
                  }[tagData.tag]
                "
              />
            </div>
          </q-card-section>

          <q-card-section class="q-pb-sm">
            <h4 v-html="vehicle.title" class="--no-style q-mb-none" />
            <p class="q-ma-none text-text-light">{{ vehicle.trim }}</p>
          </q-card-section>

          <q-card-section class="q-py-sm">
            <div class="row no-wrap items-end justify-between">
              <div class="row no-wrap items-center">
                <h3 class="text-primary q-mb-none" style="font-weight: 800">
                  {{ vehicle.price_formatted }}
                </h3>
                <div
                  v-if="
                    vehicle.tags.filter(tag => tag.tag === 'Price Drop')
                      .length > 0
                  "
                  class="column items-center justify-center text-positive q-ml-sm"
                >
                  <q-icon
                    name="sym_r_trending_down"
                    style="font-size: 24px;line-height: 24px"
                  />
                  <span
                    class="leading-none text-weight-bold"
                    style="margin-top: -6px"
                    >${{
                      formatNumber(
                        vehicle.tags.filter(tag => tag.tag === "Price Drop")[0]
                          .metadata.amount_change,
                        true
                      )
                    }}</span
                  >
                </div>
              </div>
              <div class="column items-end">
                <h6 class="--no-style q-mb-none row no-wrap items-center">
                  <q-icon name="sym_r_speed" class="q-mr-xs" size="22px" />
                  <span
                    v-html="vehicle.mileage_formatted + ' miles'"
                    class="ellipsis"
                  />
                  <!--<q-icon v-if="vehicle.tags.filter(tag => tag.tag === 'Low Mileage').length > 0" name="sym_r_keyboard_double_arrow_down" size="27px" color="positive-dark" style="margin: -2px -5px 0 2px"/>
                  <q-icon v-if="vehicle.tags.filter(tag => tag.tag === 'High Mileage').length > 0" name="sym_r_keyboard_double_arrow_up" size="27px" color="negative-dark" style="margin: 0 -5px 0 2px"/>-->
                </h6>
                <p
                  class="row no-wrap items-center q-mb-none --text-sm text-text-light"
                  style="white-space: nowrap; margin-top: 2px"
                >
                  Est. ${{ vehicle.estimated_monthly_payment }}/mo
                  <q-icon class="q-ml-xs" name="sym_r_info" size="12px">
                    <q-tooltip
                      anchor="bottom right"
                      self="top right"
                      :offset="[5, 5]"
                      max-width="300px"
                    >
                      Monthly payment estimate of ${{
                        vehicle.estimated_monthly_payment
                      }}
                      is based on a 72 month loan at 5.2% APR with a 20% down
                      payment.
                    </q-tooltip>
                  </q-icon>
                </p>
              </div>
            </div>
          </q-card-section>

          <q-separator inset />

          <q-card-section class="q-py-sm">
            <div
              class="row no-wrap items-center justify-between --text-sm text-text-light"
            >
              <p
                class="row items-center no-wrap --no-style q-mb-none"
                style="font-weight: 500"
              >
                <q-icon name="sym_r_place" class="q-mr-xs" size="15px" />
                <span
                  v-html="vehicle.dealer.city + ', ' + vehicle.dealer.state"
                  class="ellipsis"
                />
              </p>
              <p
                v-if="'distance' in vehicle"
                class="truncate"
                style="margin-top: -2px"
              >
                {{ vehicle.distance + " miles away" }}
              </p>
            </div>
          </q-card-section>
        </q-card>

        <q-card v-if="vehicle" class="bg-background-light q-mt-lg">
          <q-card-section
            class="listing__content-card__header flex items-center no-wrap"
          >
            <!--<q-icon name="sym_r_perm_phone_msg" class="q-mr-sm" size="20px" />-->
            <h6 class="q-mb-none">Check Availability</h6>
          </q-card-section>
          <q-card-section>
            <jfpc-vehicle-lead-form
              dark-mode
              publisher-account-id="10001"
              component-placement="srp"
              component-theme="carscience"
              :channel-one="
                urlParams().get('utm_campaign')
                  ? urlParams().get('utm_campaign')
                  : ''
              "
              :product-id="vehicle.product_id"
              submit-label="Submit"
              @submitted="leadSubmitted = true"
            />
          </q-card-section>
        </q-card>
      </div>
    </div>

    <div v-else-if="loading" class="listing__placeholder">
      <div class="row items-start no-wrap">
        <div class="q-gutter-y-md" style="width: 100%">
          <q-skeleton height="60vh" width="100%" animation="fade" />
          <q-skeleton height="300px" width="100%" animation="fade" />
          <div class="row no-wrap q-gutter-x-md">
            <q-skeleton height="300px" width="50%" animation="fade" />
            <q-skeleton height="300px" width="50%" animation="fade" />
          </div>
        </div>

        <div
          class="listing__placeholder-side-content q-ml-md q-gutter-y-sm"
          style="width: 500px"
        >
          <q-skeleton type="rect" height="30px" animation="fade" />
          <q-skeleton type="rect" height="30px" width="85%" animation="fade" />

          <q-skeleton
            type="rect"
            height="30px"
            width="33%"
            animation="fade"
            class="q-mt-lg"
          />

          <q-skeleton
            type="rect"
            animation="fade"
            width="97%"
            class="q-mt-xl"
          />
          <q-skeleton type="rect" animation="fade" width="92%" />
          <q-skeleton type="rect" animation="fade" width="96%" />
          <q-skeleton type="rect" animation="fade" width="95%" />
        </div>
      </div>
    </div>

    <div v-if="!vehicleLoaded && !loading">
      <div class="text-weight-bold text-center q-mt-xl">
        <q-icon
          name="sym_r_car_crash"
          style="font-size: 100px"
          class="q-mb-md text-text-light"
        />
        <h5>
          Sorry, this vehicle is no longer available.
        </h5>
      </div>
    </div>
  </section>
</template>

<script>
import ImageCarousel from "@/components/UI/ImageCarousel";
import axios from "axios";
import { Dialog } from "quasar";
import LeadFormDialog from "@/components/LeadFormDialog.vue";

export default {
  name: "Listing",
  components: {
    // LeadFormDialog,
    ImageCarousel
  },
  data() {
    return {
      loading: true,
      vehicle: {},
      leadSubmitted: false
    };
  },
  computed: {
    vehicleLoaded() {
      return Object.keys(this.vehicle).length > 0;
    },
    vehicleDetailsListData() {
      return [
        {
          label: "VIN",
          value: this.vehicle.vin
        },
        {
          label: "Year",
          value: this.vehicle.year
        },
        {
          label: "Trim",
          value: !this.vehicle["trim"] ? "-" : this.vehicle["trim"]
        },
        {
          label: "Body Type",
          value:
            this.vehicle.body_type_raw +
            " <small>(" +
            this.vehicle.body_type +
            ")</small>"
        },
        {
          label: "Mileage",
          value: this.vehicle.mileage_formatted
        },
        {
          label: "MPG",
          value:
            !this.vehicle["highway_mpg"] || this.vehicle["highway_mpg"] <= 1
              ? "N/A"
              : this.vehicle["highway_mpg"] + " (Hwy)"
        },
        {
          label: "Fuel Type",
          value: this.vehicle.fuel_type
        },
        {
          label: "Transmission",
          value:
            this.vehicle.transmission_raw +
            " <small>(" +
            this.vehicle.transmission +
            ")</small>"
        },
        {
          label: "Drivetrain",
          value: !this.vehicle.drivetrain_raw
            ? "N/A"
            : this.vehicle.drivetrain_raw +
              " <small>(" +
              this.vehicle.drivetrain +
              ")</small>"
        },
        {
          label: "Engine Type",
          value: this.vehicle.engine_type
        },
        {
          label: "Ext. Color",
          value:
            (!this.vehicle.exterior_color_raw
              ? "N/A"
              : this.vehicle.exterior_color_raw) +
            " <small>(" +
            this.vehicle.exterior_color +
            ")</small>"
        },
        {
          label: "Int. Color",
          value:
            (!this.vehicle.interior_color_raw
              ? "N/A"
              : this.vehicle.interior_color_raw) +
            " <small>(" +
            this.vehicle.interior_color +
            ")</small>"
        }
      ];
    }
  },
  watch: {},
  created() {},
  mounted() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    this.getVehicle();
  },
  unmounted() {},
  methods: {
    getVehicle() {
      axios
        .post(
          "https://api-v2.jumpfeed.com/network/publisher/products/vehicles",
          {
            filters: [{ product_id: { equals: this.$route.params.productId } }],
            options: {
              location_search: {
                postal_code: this.$store.state.search.location.postalCode,
                radius: 5000,
                include_distance_field: true
              }
            }
          }
        )
        .then(response => {
          let vehicles = response.data.data.results;

          if (vehicles.length === 0) {
            this.loading = false;
            return;
          }

          setTimeout(() => {
            this.vehicle = vehicles[0];

            this.vehicle["estimated_monthly_payment"] = this.formatNumber(
              this.getEstimatedMonthlyVehiclePayment(this.vehicle.price)
            );

            if (
              Math.abs(
                (new Date(this.vehicle.time_created) - new Date()) / 36e5
              ) < 168
            ) {
              this.vehicle.tags.unshift({
                tag: "New Listing",
                metadata: {},
                time_created: ""
              });
            }
            /* if(this.vehicle.mileage / (((new Date().getFullYear()) - this.vehicle.year) + 0.5) <= 10000) {
              this.vehicle.tags.push({
                tag: "Low Mileage",
                metadata: {},
                time_created: ""
              });
            }
            if(this.vehicle.mileage / (((new Date().getFullYear()) - this.vehicle.year) + 0.5) >= 20000) {
              this.vehicle.tags.push({
                tag: "High Mileage",
                metadata: {},
                time_created: ""
              });
            }*/

            this.loading = false;
          }, 250);
        });
    },
    promptLeadFormDialog() {
      Dialog.create({
        component: LeadFormDialog,
        componentProps: {
          vehicle: this.vehicle
        }
      });
    },
    navigateBack() {
      document.referrer.includes("carscience.com") ||
      document.referrer.includes("localhost")
        ? this.$router.go(-1)
        : this.$router.push({ path: "/search" });
    }
  }
};
</script>

<style scoped lang="scss">
.listing {
  @include full-screen-height;
  padding: 0;
  // max-width: 1200px;
  margin: 0 auto;

  .listing__action-bar {
    @include contain-content-width;
    background: color(background);
    padding-top: $space-md;
    padding-bottom: $space-md;
  }

  .listing__content {
    @include contain-content-width;
    @include display-flex(row, flex-start, flex-start);

    @include screen-md {
      @include display-flex(column, flex-start, flex-start);
    }

    .listing__main-content {
      width: 100%;
      @include full-screen-height;
      font-size: 16px;

      .listing__content-card {
        background: color(background, light);
      }
    }

    .listing__side-content {
      min-width: 410px;
      max-width: 410px;
      width: 100%;
      margin-left: $space-md;
      @include full-screen-height;
      position: sticky;
      top: calc(#{$titlebar-height} + #{$space-md});

      @include screen-md {
        display: none;
        min-width: unset;
      }
    }

    .listing__content-card__header {
      /* box-shadow: $shadow-bottom; */
      margin-bottom: $space-xs;
      background: color(background, very-light);

      h5 {
        margin-bottom: 0;
        /*@include display-flex(row, flex-start, center);
        flex-wrap: nowrap;
        font-weight: 800;
        width: fit-content;
        border-bottom: 3px solid color(secondary);
        padding-bottom: $space-md;
        margin: 0 0 -#{$space-md};*/
      }
    }

    .listing__vehicle-details-list-item {
      border-bottom: 1px solid color(border);

      &:nth-last-of-type(2),
      &:last-of-type {
        border-bottom: none;
      }

      @include screen-md {
        &:nth-last-of-type(2) {
          border-bottom: 1px solid color(border);
        }
        &:last-of-type {
          border-bottom: none;
        }
      }

      .listing__vehicle-details-list-item-content {
        @include display-flex(row, flex-start, center);

        @include screen-md {
          @include display-flex(column, flex-start, flex-start);
        }
      }
    }
  }

  .listing__placeholder {
    margin-top: $space-md;
    margin-bottom: $space-xl;
    @include contain-content-width;

    .listing__placeholder-side-content {
      @include screen-md {
        display: none;
      }
    }
  }
}
</style>
